import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import colors from "./style/colors";
import styled, { keyframes } from "styled-components";
import P1 from "./typography/P1";
import Close from "../../assets/glyphs/smallClose.svg";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
`;

const BannerWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 0 1em 1em;
  right: 0;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 30;
  animation: ${fadeIn} 0.2s 0.5s backwards;

  ${props =>
    !props.show &&
    `
    display: none;
  `}

  div {
    padding: 0.4em 0.8em;
    border-radius: 0.3em;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid rgba(0, 0, 0, 0.3);
    // color: white;
    font-size: 0.9em;
    a {
      text-decoration: underline;
    }
    p {
      margin: 0;
      font-size: 0.9em;
    }
    svg {
      width: 0.7em;
      height: 0.7em;
      display: inline-block;
      border: none;
      margin-left: 0.7em;
      opacity: 0.5;
      cursor: pointer;
      path {
        fill: ${colors.ultra_dark};
      }
      &:hover {
        opacity: 1;
      }
    }
  }
`;

const CookieBanner = props => {
  const [cookieDismissed, setCookieDismissed] = useState(false);

  // if (cookieDismissed) return null;

  useEffect(() => {
    if (localStorage.getItem("cookieDismissed")) {
      setCookieDismissed(true);
    }
  }, []);

  useEffect(() => {
    if (cookieDismissed) {
      localStorage.setItem("cookieDismissed", true);
    }
  }, [cookieDismissed]);

  return (
    <BannerWrapper show={!cookieDismissed}>
      <div>
        <P1>
          {cookieDismissed} Using this website you adhere to our <Link to="/privacy-policy">Privacy Policy</Link> and{" "}
          <Link to="/cookie-policy">Cookie Policy</Link>.
        </P1>
        <Close
          onClick={() => {
            setCookieDismissed(true);
          }}
        />
      </div>
    </BannerWrapper>
  );
};

export default CookieBanner;
