import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomSelect from "../components/ui/CustomSelect";
import CustomInput from "../components/ui/CustomInput";

const RoiCalculator = () => {
  const [opens, setOpens] = useState();
  const [clicks, setClicks] = useState();
  const [conversions, setConversions] = useState();
  const [revenue, setRevenue] = useState();
  const [profit, setProfit] = useState();
  const [breakEven, setBreakEven] = useState();
  const [roi, setRoi] = useState();
  const calcBtn = useRef();

  const [curr, setCurr] = useState("$");

  const options = [
    {
      label: "$ USD",
      value: "USD"
    },
    {
      label: "£ Pound",
      value: "Pound"
    },
    {
      label: "€ Euros",
      value: "Euro"
    }
  ];

  const defaultValues = {
    currency: "USD",
    volume: 10000,
    cost: 10,
    openRate: 20,
    clickRate: 10,
    conversionRate: 1,
    averageConversionValue: 10

    // currency: '',
    // volume: '',
    // cost: '',
    // openRate: '',
    // clickRate: '',
    // conversionRate: '',
    // averageConversionValue: ''
  };

  const schema = yup
    .object({
      volume: yup
        .number()
        .required("Volume is required")
        .min(0, "Must be greater than 0.")
        .typeError("Must be greater than 0"),
      cost: yup
        .number()
        .typeError("Cost is required")
        .min(0, "Must be greater than 0.")
        .required("Cost is required"),
      openRate: yup
        .number()
        .typeError("Open Rate is required")
        .min(0, "Must be greater than or equal to 0 or less than or equal to 100")
        .max(100, "Must be greater than or equal to 0 or less than or equal to 100")
        .required(),
      clickRate: yup
        .number()
        .typeError("Click Rate is required")
        .min(0, "Must be greater than or equal to 0 or less than or equal to 100")
        .max(100, "Must be greater than or equal to 0 or less than or equal to 100")
        .required(),
      conversionRate: yup
        .number()
        .typeError("Conversion Rate is required")
        .min(0, "Must be greater than or equal to 0 or less than or equal to 100")
        .max(100, "Must be greater than or equal to 0 or less than or equal to 100")
        .required(),
      averageConversionValue: yup
        .number()
        .min(0, "Must be greater than 0.")
        .typeError("Net Conversion value is required")
        .required()
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  });

  useEffect(() => {
    calcBtn.current.click();
  }, []);

  const onSubmit = values => {
    if (values.currency === "Pound") {
      setCurr("£");
    } else if (values.currency === "Euro") {
      setCurr("€");
    } else setCurr("$");

    const resultOpens = (values.openRate * values.volume) / 100;
    setOpens(resultOpens.toFixed(0));

    const resultClicks = (values.clickRate / 100) * resultOpens;
    setClicks(resultClicks.toFixed(0));

    const resultConversions = (values.conversionRate * resultClicks) / 100;
    setConversions(resultConversions.toFixed(3));

    const resultRevenue = resultConversions * values.averageConversionValue;
    setRevenue(resultRevenue.toFixed(2));

    const resultProfit = resultRevenue - values.cost;
    setProfit(resultProfit.toFixed(2));

    const resultBreakEven = values.cost / values.averageConversionValue;
    setBreakEven(Number.isNaN(resultBreakEven) ? 0 : resultBreakEven.toFixed(2));

    const resultRoi = (resultProfit / values.cost) * 100;
    setRoi(Number.isNaN(resultRoi) ? 0 : resultRoi.toFixed(2));
  };

  return (
    <>
      <div className="formSection">
        <div className="topSection">
          <h1>Email Marketing ROI Calculator</h1>
          <p className="topPara">
            Boxysuite’s Email Marketing ROI calculator is the only tool you need to calculate return on investment for
            your email marketing campaign.
          </p>
        </div>
        <div className="container">
          <div className="col-md-10 offset-md-1">
            <div className="formContainer">
              <div className="row">
                <div className="col-md-6">
                  <div className="calculatorColumn">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="currency">
                        <h6>Currency</h6>
                        <p>Choose the currency you'd like to use to calculate your email marketing ROI</p>
                        <CustomSelect control={control} name="currency" options={options} />
                      </div>
                      <div className="emailVolume">
                        <h6>Email Send Volume*</h6>
                        <p>Enter the total number of subscribers that your email marketing campaign is sent to.</p>
                        <CustomInput control={control} name="volume" type="number" />
                        <p className="errorMessage">{errors.volume?.message}</p>
                      </div>
                      <div className="emailCampaignCost">
                        <h6>Cost of Email Marketing Campaign*</h6>
                        <p>
                          Enter the cost for sending this single campaign. (Divide your monthly cost by the total
                          campaigns sent per month.)
                        </p>
                        <CustomInput placeholder={curr} control={control} type="number" name="cost" />
                        <p className="errorMessage">{errors.cost?.message}</p>
                      </div>
                      <div className="openRate">
                        <h6>Open Rate*</h6>
                        <p>Enter the percentage of people, on average, that open your emails</p>
                        <CustomInput control={control} placeholder="%" type="number" name="openRate" />
                        <p className="errorMessage">{errors.openRate?.message}</p>
                      </div>
                      <div className="clickRate">
                        <h6>Click-Through Rate*</h6>
                        <p>
                          Enter the percentage of subscribers who clicked through your email campaign call to action.
                        </p>
                        <CustomInput control={control} placeholder="%" type="number" name="clickRate" />
                        <p className="errorMessage">{errors.clickRate?.message}</p>
                      </div>
                      <div className="conversionRate">
                        <h6>Conversion Rate*</h6>
                        <p>Enter the percentage of people, on average, that click on links in your email</p>
                        <CustomInput control={control} placeholder="%" type="number" name="conversionRate" />
                        <p className="errorMessage">{errors.conversionRate?.message}</p>
                      </div>
                      <div className="averageConversionValue">
                        <h6>Average Value of a conversion*</h6>
                        <p>
                          Enter the average spend per customer (ASC) (or average order value as it’s known in Google
                          Analytics).
                        </p>
                        <CustomInput control={control} type="number" name="averageConversionValue" />
                        <p className="errorMessage">{errors.averageConversionValue?.message}</p>
                      </div>
                      <button type="submit" ref={calcBtn}>
                        Calculate ROI
                      </button>
                    </form>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="resultColumn">
                    <div className="resultsSection">
                      <h6>Results</h6>
                    </div>
                    <div className="totalOpens">
                      <p>Total Opens</p>
                      <p className="res">{opens}</p>
                    </div>
                    <div className="totalOpens">
                      <p>Total Clicks</p>
                      <p className="res">{clicks}</p>
                    </div>
                    <div className="totalOpens">
                      <p>Total Conversions</p>
                      <p className="res">{conversions}</p>
                    </div>
                    <div className="totalOpens">
                      <p>Total Revenue</p>
                      <p className="res">{revenue}</p>
                    </div>
                    <div className="totalOpens">
                      <p>Total Profit</p>
                      <p className="res">{profit}</p>
                    </div>
                    <div className="totalOpens">
                      <p>Conversion to Break Even</p>
                      <p className="res">{breakEven}</p>
                    </div>

                    <div className="ROI">
                      <p>ROI (%)</p>
                      <p className="res">{roi}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoiCalculator;
